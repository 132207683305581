import React from "react";
import { Link } from "gatsby";
import config from "../../../config";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <p>{config.copyright}</p>
          <p>
            <Link to="/impressum">Impressum</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
