module.exports = {
  siteTitle: "Frickly Systems", // Site title.
  siteTitleAlt: "Frickly Systems", // Alternative site title for SEO.
  siteLogo: "/icons/icon-512x512.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.frickly-systems.de", // Domain of your website without pathPrefix.
  pathPrefix: "", // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: "Frickly Systems website.", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml",
  siteFBAppID: "", // FB Application ID for using app insights
  googleTagManagerID: "", // GTM tracking ID.
  disqusShortname: "", // Disqus shortname.
  userName: "",
  userTwitter: "",
  userLocation: "",
  userDescription: "",
  copyright: "Copyright © Frickly Systems GmbH 2020. All Rights Reserved.", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#000000", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff", // Used for setting manifest background color.
};
